import { Component, Input, OnChanges } from '@angular/core';
import { Solar, SolarInstance, WebSolarLossesService, WebSolarReportService } from '@websolar/ng-websolar';

@Component({
    selector: 'app-report-losses',
    templateUrl: './report-losses.component.html',
    styleUrls: ['./report-losses.component.scss']
})
export class ReportLossesComponent implements OnChanges {

    @Input() project?: Solar.Project;

    @Input() objects?: Solar.Object[];

    public losses = {
        /**
         * Optimal irradiance losses in percent
         */
        optimalIrradiance: 0,
        /**
         * Orientation losses in percent
         */
        orientation: 0,
        /**
         * Shading losses in percent
         */
        shading: 0,
        /**
         * Soiling losses in percent
         */
        soling: 0,
        /**
         * Output #1 losses
         * value: kWh
         * percent: percent (%)
         */
        output1: {
            value: 0,
            percent: 0
        },
        /**
         * Temperature losses in percent
         */
        temperature: 0,
        /**
         * Electrical losses in percent
         */
        electrical: 0,
        /**
         * Optimizer losses in percent
         */
        optimizer: 0,
        /**
         * DC wiring losses in percent
         */
        DCWiring: 0,
        /**
         * Snow losses in percent
         */
        snow: 0,
        /**
         * Output #2 losses
         * value: kWh
         * percent: percent (%)
         */
        output2: {
            value: 0,
            percent: 0
        },
        /**
         * Inverter losses in percent
         */
        inverter: 0,
        /**
         * AC wiring losses in percent
         */
        ACWiring: 0,
        /**
         * Maintenance losses in percent
         */
        maintenance: 0,
        /**
         * Output #3 losses
         * value: kWh
         * percent: percent (%)
         */
        output3: {
            value: 0,
            percent: 0
        },

        /**
         * Visual offsets for bars
         */
        offsets: {
            offset1: 0,
            offset2: 0,
            offset3: 0,
            offset4: 0,
            offset5: 0,
            offset6: 0,
            offset7: 0,
            offset8: 0,
            offset9: 0,
            offset10: 0
        }
    }

    constructor(
        private _reportService: WebSolarReportService
    ) { }

    public async ngOnChanges() {
        if (this.objects && this.project) {
            await this.init();
        }
    }

    private async init() {
        if (!this.project ||
            !this.project.powerOutput ||
            !this.project.basicLosses ||
            !this.objects) {
            return;
        }

        // get the total irradiance from modules
        const modules = this.objects.filter(o => o.type == "module") as Solar.ObjectModule[];
        const modulesOutput = modules.filter(m => m.output).map(m => m.output) as Solar.ProductionOutput[];
        const maxActualIrradiance = (modulesOutput.reduce((prev, cur) => Math.max(prev, cur.radiance), 0)); // kWh/m2

        this.losses.orientation = this.project.basicLosses.orientation;
        this.losses.shading = this.project.basicLosses.shading;
        this.losses.temperature = this.project.basicLosses.temperature;
        this.losses.soling = this.project.systemLoses.soling;
        this.losses.ACWiring = this.project.systemLoses.wiring;
        this.losses.electrical = this.project.systemLoses.mismatch;
        this.losses.snow = this.project.systemLoses.snow;
        this.losses.maintenance = this.project.systemLoses.availability;

        if (this.project.dcLosses) {
            this.losses.DCWiring = this.project.dcLosses.voltageDrop;
            this.losses.inverter = this.project.dcLosses.inverter;
            this.losses.optimizer = this.project.dcLosses.optimizer;
        }

        // calculate the optimal output
        //
        const noShading = (maxActualIrradiance / (1 - (this.project.basicLosses.shading / 100)));
        this.losses.optimalIrradiance = Math.round((noShading / (1 - (this.project.basicLosses.orientation / 100))));

        // calculate the output #1
        //

        // Set output #1
        const output1Percent = Math.round(100 - (this.losses.soling + this.losses.orientation + this.losses.shading));
        this.losses.output1 = {
            value: 0,
            percent: output1Percent
        }

        // Set output #2
        const output2Losses = this.losses.temperature + this.losses.electrical + this.losses.optimizer + this.losses.DCWiring + this.losses.snow;
        const output2Percent = Math.round(output1Percent - output2Losses);
        this.losses.output2 = {
            value: 0,
            percent: output2Percent
        }

        // Set output #3
        const output3Losses = this.losses.inverter + this.losses.ACWiring + this.losses.maintenance;
        const output3Percent = Math.round(output2Percent - output3Losses);
        this.losses.output3 = {
            value: 0,
            percent: output3Percent
        }

        // calculate production values
        //

        // get the final production
        const productionInfo = this._reportService.getProduction(this.project, this.objects);
        const production = Math.round(productionInfo.productionPower);

        this.losses.output3.value = production;

        // calc output losses #2
        this.losses.output2.value = production;
        this.losses.output2.value = this.losses.output2.value / ((100 - this.losses.maintenance) / 100);
        this.losses.output2.value = this.losses.output2.value / ((100 - this.losses.ACWiring) / 100);
        this.losses.output2.value = this.losses.output2.value / ((100 - this.losses.inverter) / 100);

        // calc output losses #1
        this.losses.output1.value = this.losses.output2.value;
        this.losses.output1.value = this.losses.output1.value / ((100 - this.losses.DCWiring) / 100);
        this.losses.output1.value = this.losses.output1.value / ((100 - this.losses.optimizer) / 100);
        this.losses.output1.value = this.losses.output1.value / ((100 - this.losses.electrical) / 100);
        this.losses.output1.value = this.losses.output1.value / ((100 - this.losses.temperature) / 100);
        this.losses.output1.value = this.losses.output1.value / ((100 - this.losses.snow) / 100);

        // Set offsets
        //
        this.losses.offsets.offset1 = this.losses.orientation;
        this.losses.offsets.offset2 = this.losses.offsets.offset1 + this.losses.soling;
        this.losses.offsets.offset3 = this.losses.offsets.offset2 + this.losses.shading;
        this.losses.offsets.offset4 = this.losses.offsets.offset3 + this.losses.snow;
        this.losses.offsets.offset5 = this.losses.offsets.offset4 + this.losses.temperature;
        this.losses.offsets.offset6 = this.losses.offsets.offset5 + this.losses.electrical;
        this.losses.offsets.offset7 = this.losses.offsets.offset6 + this.losses.optimizer;
        this.losses.offsets.offset8 = this.losses.offsets.offset7 + this.losses.DCWiring;
        this.losses.offsets.offset9 = this.losses.offsets.offset8 + this.losses.inverter;
        this.losses.offsets.offset10 = this.losses.offsets.offset9 + this.losses.ACWiring;
    }

}
