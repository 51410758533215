import QRCode from 'qrcode'
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WebSolarAuthService, NotifyService, WebSolarShareService, Solar } from '@websolar/ng-websolar';
import { assetUrl } from 'src/app/pipes/asset.url.pipe';
import { createCanvas } from 'canvas';


@Component({
    selector: 'app-share-panel',
    templateUrl: './share-panel.component.html',
    styleUrls: ['./share-panel.component.scss']
})
export class SharingPanelComponent implements OnChanges {

    @Input() project!: Solar.Project;

    public link = "";

    public isLoading = false;

    public isPremium = false;

    public qrCode = "";

    public filename = "qrcode.png";

    constructor(
        private _auth: WebSolarAuthService,
        private _shareService: WebSolarShareService,
        private _notify: NotifyService,
        private _translate: TranslateService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.project) {
            this.load();
        }
    }

    private async load() {
        try {
            this.isLoading = true;
            this.isPremium = this._auth.isPremium();
            this.filename = `${this.project.name}.png`;
            if (this._auth.isSigned()) {
                // get the share link
                const lang = this._translate.currentLang;
                this.project.shareId = await this._shareService.share(this.project._id || "");
                this.link = `${document.location.protocol}//${document.location.host}/${lang || "en"}/solar/share/${this.project.shareId}`;

                await this.buildQRcode();
            }
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    /**
     * Builds a QR code with a logo.
     * @returns {Promise<void>} A promise that resolves when the QR code is built.
     */
    private async buildQRcode() {
        const canvas = document.createElement("canvas") as HTMLCanvasElement;
        canvas.width = 200;
        canvas.height = 200;
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            console.error("no context");
            return;
        }

        // Generate QR code on the canvas
        await QRCode.toCanvas(canvas, this.link, { width: 200 });


        // Load the logo image
        const logo = await this.loadImage(assetUrl("/icons/aiko-icon.png"),);

        // Calculate the position and size of the logo
        const logoSize = 30;
        const x = (canvas.width - logoSize) / 2;
        const y = (canvas.height - logoSize) / 2;

        // Draw the logo on the QR code
        ctx.drawImage(logo, x, y, logoSize, logoSize);

        // Return the QR code as a data URL
        this.qrCode = canvas.toDataURL();
    }

    public copyToClipboard() {
        // Copy the text inside the text field
        navigator.clipboard.writeText(this.link);
    }


    private loadImage(src: string) {
        return new Promise<HTMLImageElement>((resolve) => {
            let img = new Image();

            img.onload = () => {
                resolve(img);
            };
            img.setAttribute('crossorigin', 'anonymous');

            img.src = src;

            return img;
        })
    }
}
