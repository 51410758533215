import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SolarInstance, Solar, NotifyService, WebSolarEventsService, WebSolarWiringService, WebSolarColors, WebSolarInverterService, WebSolarReportService, WebSolarGeometryService } from '@websolar/ng-websolar';
import { Observable, Subscription } from 'rxjs';
import { DialogService } from 'src/app/services/dialog.service';
import { OptimizerService } from 'src/app/services/optimizer.service';
import { InvertersAddingComponent } from '../inverters-adding/inverters-adding.component';
import { InverterService } from 'src/app/services/inverter.service';
import { InverterEditFieldsComponent } from '../inverter-edit-fields/inverter-edit-fields.component';
import { CloneTool } from 'src/app/core/clone.tool';
import { AIKO } from 'src/app/types/aiko.types';
import { OptimizerPickerWindowComponent } from 'src/app/optimizers/optimizer-picker-window/optimizer-picker-window.component';
import { TranslateService } from '@ngx-translate/core';
import { Geometry } from 'src/app/core/geometry';

export interface InverterGroupItem {
    name: string,
    inverters: Solar.StringConfigItem[],
    expanded: boolean
}

@Component({
    selector: 'app-inverters-list',
    templateUrl: './inverters-list.component.html',
    styleUrls: ['./inverters-list.component.scss']
})
export class InvertersListComponent implements OnChanges, OnDestroy, OnInit {

    @Input() instance!: SolarInstance;

    @Input() project!: AIKO.ProjectExt;

    @Input() events!: Observable<{ name: string, params: unknown }>;

    @Output() inverterChange = new EventEmitter<Solar.ObjectInverter>();

    @Output() toolActivated = new EventEmitter<{ type: Solar.ToolType, params: unknown }>();

    public capacityRatio = 120;

    /**
     * Capacity ratio = total power of all roof components / total rated power of all inverters.
     */
    public actualCapacityRatio = 0;

    /**
     * Indicates whether the optimizer is currently running.
     */
    public isOptimizerRunning = false;

    /**
     * Indicates whether the optimizer placement is currently running.
     */
    public isOptimizerPlacing = false;

    public hasOptimizer = false;

    /**
     * The number of optimizers.
     */
    public optimizerCount = 0;

    public completedOptimizerCount = 0;

    /**
     * The number of inverters in the electrical panel.
     */
    public invertersCount = 0;

    /**
     * The number of modules represented as a string.
     */
    public modulesInString = 0;

    /**
     * The total number of modules.
     */
    public totalModules = 0;

    /**
     * The total number of strings.
     */
    public totalStrings = 0;

    public completedStrings = 0;

    public groups: InverterGroupItem[] = [];

    public itemState: { [key: string]: { expanded: boolean } } = {};

    public strTreeItems: { [key: string]: { attachedString: Solar.StringConfigItem | null }[] } = {};

    public availStrings: { string: Solar.ObjectString, name: string }[] = [];

    public isProcessing = false;

    public pickedObjectId: string = "";

    public voltageList = [
        { id: "380", name: "380V, three-phase" },
        { id: "220", name: "220V, single-phase" }
    ];

    private _subs: Subscription[] = [];

    private static _lastOptimizerMode = "";

    constructor(
        private _dialogService: DialogService,
        private _notify: NotifyService,
        private _eventsService: WebSolarEventsService,
        private _optimizerService: OptimizerService,
        private _wiringService: WebSolarWiringService,
        private _wsInverterService: WebSolarInverterService,
        private _matDialog: MatDialog,
        private _inverterService: InverterService,
        private _reportService: WebSolarReportService,
        private _translate: TranslateService,
        private _geomService: WebSolarGeometryService
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        try {
            if (this.project && this.instance) {
                this.init();
            }
        }
        catch (err) {
            console.error();
        }
    }

    public ngOnInit() {
        window.addEventListener('keydown', this.handleDeleteKeyPress);
    }

    /**
     * Handles the key press event for the delete key.
     * 
     * @param event - The keyboard event object.
     */
    private handleDeleteKeyPress = (event: KeyboardEvent) => {
        try {
            if (event.key === 'Delete') {
                if (this.pickedObjectId) {

                    const item = this.instance.getObjects({ id: this.pickedObjectId })[0];
                    if (!item || item.type != "string") {
                        return;
                    }

                    // remove children
                    this.instance.removeObjects({
                        ownerId: this.pickedObjectId
                    });

                    // delete inverter as well
                    this.instance.removeObjects({
                        id: this.pickedObjectId
                    });

                    // sync the config
                    this._wiringService.sync(this.instance, this.project);

                    // rebuild
                    this.rebuild();

                    this.pickedObjectId = "";
                }
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    private attachToEvents() {
        const sub = this._eventsService.eventsAsObservable.subscribe((opt) => {
            if (opt.name == "tool_completed" ||
                opt.name == "object_deleted" ||
                opt.name == "undo" ||
                opt.name == "redo" ||
                opt.name == "detachString" ||
                opt.name == "segment_edge_changed") {
                this.rebuild();

                if (opt.name == "tool_completed") {
                    const args = opt.params as { type: Solar.ToolType; result: unknown };
                    if (args.type == "wiring") {
                        // verify string
                        this.verifyNewString(args.result as Solar.ObjectString);
                    }
                }
            }
            else if (opt.name == "object_picked") {
                this.onObjectPicked(opt.params as Solar.Object);
            }
            else if (opt.name == "object_double_clicked") {
                this.startWiring(opt);
            }
        });
        this._subs.push(sub);

        const sub2 = this.instance.objectEditor.objectStartChange.subscribe((obj) => {
            if (!obj || !obj.userData) {
                return;
            }
            const solarObject = obj.userData as Solar.Object;
            this.onObjectPicked(solarObject);
        })
        this._subs.push(sub2);
    }

    public ngOnDestroy(): void {
        for (const s of this._subs) {
            s.unsubscribe();
        }
        // Clean up the event listener
        window.removeEventListener('keydown', this.handleDeleteKeyPress);
    }


    private async init() {
        try {
            if (!this.instance || !this.project) {
                return;
            }

            if (!this.project.voltageLevel) {
                this.project.voltageLevel = "380";
            }

            if (!this.project.electrical.stringsConfig ||
                !this.project.electrical.stringsConfig.items ||
                !this.project.electrical.stringsConfig.items.length) {
                // build default
                this._wiringService.clearConfig(this.instance, this.project);
            }
            else {
                this._wiringService.sync(this.instance, this.project);
            }

            const aikoProj = this.project as AIKO.ProjectExt;
            this.capacityRatio = aikoProj.capacityRatio || 120;


            this.rebuild();

            this.refreshStat();

            this.attachToEvents();

            // verify
            //
            const inverters = this.instance.getObjects({ types: ["inverter"] }) as Solar.ObjectInverter[];
            await this.verifyInverters(inverters);
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    private async verifyNewString(newString: Solar.ObjectString) {
        try {
            if (!newString || newString.type != "string") {
                // invalid input
                console.error(`invalid input`)
                return;
            }

            const modulesIds = newString.moduleIds;
            const modules = this.instance.getObjects({ id: newString.moduleIds });
            const owner = modules[0].owner;
            for (const m of modules) {
                if (m.owner != owner) {

                    this._dialogService.confirm({
                        text: `Modules of the same model with the same inclination and azimuth can be connected in series`,
                        title: "",
                        hideCancel: true
                    })

                    this.instance.removeObjects({ id: newString.id });

                    return;
                }
            }

        }
        catch (err) {
            this._notify.error(err);
        }
    }

    /**
     * Starts the wiring process.
     * @param opt - The options for starting the wiring process.
     * @param opt.name - The name of the wiring process.
     * @param opt.params - The parameters for the wiring process.
     */
    private startWiring(opt: { name: string; params: unknown; }) {
        const solarObj = opt.params as Solar.Object;
        if (solarObj.type == "module") {

            const strings = this.instance.getObjects({ types: ["string"] }) as Solar.ObjectString[];
            for (const str of strings) {
                if (str.moduleIds.includes(solarObj.id)) {
                    // module already connected to string
                    return;
                }
            }

            this.toolActivated.emit({ type: "wiring", params: { modulesIds: [solarObj.id] } as Solar.WiringToolOptions });
        }
    }

    private onObjectPicked(object: Solar.Object) {
        try {
            this.pickedObjectId = object.id;
            if (!this.pickedObjectId) {
                return;
            }

            if (this.itemState[object.id]) {
                // expand all
                for (const [key, val] of Object.entries(this.itemState)) {
                    val.expanded = true;
                }
                for (const group of this.groups) {
                    group.expanded = true;
                }
            }

            this.instance.highlight.clear();
            const obj3ds = this.instance.get3dObjects({ id: object.id });
            const obj3d = obj3ds[0];
            if (obj3d) {
                this.instance.highlight.add(obj3d);
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    private async verifyInverters(inverters: Solar.ObjectInverter[]) {
        let isValid = true;
        for (const inv of inverters) {
            const dbInvs = await this._wsInverterService.find({ id: inv.inverter._id });
            const dbInv = dbInvs[0];
            if (!dbInv) {
                isValid = false;
                break;
            }
            if (JSON.stringify(dbInv) != JSON.stringify(inv.inverter)) {
                // inverter changed
                isValid = false;
                break;
            }
        }
        if (!isValid) {
            this._dialogService.confirm({
                text: "Please reconfigure the inverter",
                title: "",
                hideCancel: true
            })

            this.deleteWiring();
        }
    }

    private deleteWiring() {
        // clear inverters
        this.instance.removeObjects({ types: ["inverter"] });
        this._wiringService.clearConfig(this.instance, this.project);
    }

    /**
     * Rebuilds the inverters list based on the current instance and project.
     * This method updates the groups, string tree items, available strings, capacitor ratio, and statistics.
     * 
     * @private
     */
    private rebuild() {
        try {
            if (!this.instance || !this.project) {
                return;
            }

            const invs = this.instance.getObjects({ types: ["inverter"] }) as Solar.ObjectInverter[];

            const oldGroups = this.groups.slice(0);

            this.groups = [];
            this.strTreeItems = {};

            const config = this.project.electrical.stringsConfig;
            const flatTree = this._wiringService.getFlatTree(config.items);

            for (const item of flatTree) {

                if (item.object.type == "mppt") {
                    // assign id to mppt if needs
                    if (!item.object.id) {
                        // assign id
                        item.object.id = this.getUniqueId();
                    }
                }

                if (item.object.id && !this.itemState[item.object.id]) {
                    this.itemState[item.object.id] = { expanded: false };
                }
            }

            for (const invObj of invs) {

                const treeItem = flatTree.find(i => i.object.id == invObj.id);
                if (!treeItem) {
                    continue;
                }

                this.initState(invObj.id)

                const groupName = `${invObj.inverter.manufacturer} ${invObj.inverter.model}`;
                const group = this.groups.find(g => g.name == groupName);
                if (!group) {
                    const isExpanded = oldGroups.find(g => g.name == groupName)?.expanded || true;
                    this.groups.push({
                        name: groupName,
                        inverters: [treeItem],
                        expanded: isExpanded
                    })
                }
                else {
                    group.inverters.push(treeItem);
                }

                // fill string items
                for (const [mpptIndex, mppt] of treeItem.children.entries()) {

                    this.strTreeItems[mppt.object.id] = [];
                    const strTreeItem = this.strTreeItems[mppt.object.id];

                    for (const str of mppt.children) {
                        strTreeItem.push({
                            attachedString: str
                        })

                    }

                    const stringsPerMPPT = this._inverterService.getStringPerMPPT(invObj.inverter, mpptIndex);
                    let strToAdd = stringsPerMPPT - mppt.children.length;
                    for (let idx = 0; idx < strToAdd; idx++) {
                        strTreeItem.push({
                            attachedString: null
                        })
                    }
                }
            }

            // update avail strings
            const strings = this.instance.getObjects({ types: ["string"] }) as Solar.ObjectString[];
            this.availStrings = [];
            for (const str of strings) {
                if (flatTree.find(t => t.object.id == str.id)) {
                    continue;
                }
                this.availStrings.push({
                    name: this.getStringName(str),
                    string: str
                })
            }

            // sort groups by power
            this.groups.sort((g1, g2) => {
                const inv1 = g1.inverters[0].object as Solar.ObjectInverter;
                const inv2 = g2.inverters[0].object as Solar.ObjectInverter;
                return inv2.inverter.maxPower - inv1.inverter.maxPower;
            })

            // update the statistic
            this.refreshStat();
        }
        catch (err) {
            console.error();
        }
    }


    /**
     * Updates the capacity ratio based on the total power of inverters and modules.
     * Capacity ratio = total power of all roof components / total rated power of all inverters.
     */
    private updateCapacityRatio() {
        let totalInvPower = 0;
        const flatTree = this._wiringService.getFlatTree(this.project.electrical.stringsConfig.items);
        const inverters = flatTree
            .filter(s => s.object.type == "inverter")
            .map(s => s.object) as Solar.ObjectInverter[];
        for (const inv of inverters) {
            totalInvPower += inv.inverter.maxPower;
        }
        let totalModulesPower = 0;
        const modules = this.instance.getObjects({ types: ["module"] }) as Solar.ObjectModule[];
        if (modules.length) {
            const modulePower = modules[0].modulePower / 1000;
            const strings = flatTree.filter(s => s.object.type == "string").map(s => s.object) as Solar.ObjectString[];
            for (const string of strings) {
                totalModulesPower += (modulePower * string.moduleIds.length);
            }
        }

        if (totalInvPower) {
            this.actualCapacityRatio = Math.round((totalModulesPower / totalInvPower) * 1000) / 10;
        }
        else {
            this.actualCapacityRatio = 0;
        }
    }

    public async autoPlacement() {
        try {
            this.cleanupWiring();

            const isValid = this.verifyModules();
            if (!isValid) {
                return;
            }

            this.isProcessing = true;

            this.doWiring();
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isProcessing = false;
        }
    }

    private finalizeWiring() {
        this.updateServicePanel();

        // sync the config
        this._wiringService.sync(this.instance, this.project);

        this.rebuild();

        this.refreshStat();
    }

    private getSegmentsGroups() {
        const segmentGroups = new Map<string, Solar.ObjectRooftopSegment[]>();
        const roofs = this.instance.getObjects({ types: ["roof"] }) as Solar.ObjectRoof[];
        // we should perform inverters calculation for each roof
        for (const roof of roofs) {
            const roofSegments = this.instance.getObjects({ ownerId: roof.id, types: ["segment"] }) as Solar.ObjectRooftopSegment[];

            const roofTransform = this._geomService.getObjectTransform(roof);

            // check if we can combine the segments
            for (const roofSegment of roofSegments) {

                let key = `${roof.id}_${Math.round(roofSegment.azimuth)}`;

                if (roof.roofParts) {
                    // get roof part
                    for (const roofPart of roof.roofParts) {
                        const worldPoints = this._geomService.transformPoints(roofPart.points, roofTransform);
                        if (this._geomService.isInsidePg(roofSegment.points[0], worldPoints)) {
                            key += `_${roof.roofParts.indexOf(roofPart)}`;
                        }
                    }
                }

                if (!segmentGroups.has(key)) {
                    segmentGroups.set(key, [roofSegment]);
                }
                else {
                    segmentGroups.get(key)?.push(roofSegment);
                }
            }
        }
        return segmentGroups;
    }

    /**
     * Performs the wiring process for the given inverters.
     */
    private doWiringSegments(inverterObjectsIds: string[], segmentIds: string[]) {

        const wiring = this._inverterService.buildWiring({
            instance: this.instance,
            project: this.project,
            targetRatio: this.capacityRatio,
            segmentIds: segmentIds,
            inverterObjectsIds: inverterObjectsIds
        });
        console.log("wiring:", wiring, "segments:", segmentIds, "inverters:", inverterObjectsIds)

        for (let invWiring of wiring) {
            if (!invWiring.condition || !invWiring.condition.minStringSize) {
                continue;
            }
            const minStringSize = invWiring.condition.minStringSize;
            // remove the strings that not possible create
            for (const mppt of invWiring.mppt) {
                mppt.allocatedStrings = mppt.allocatedStrings.filter(size => size >= minStringSize);
            }
        }


        // wire strings and upgrade the config
        this._wiringService.buildStrings({
            instance: this.instance,
            project: this.project,
            predifinedWiring: wiring,
            mode: "s-shape",
            keepPreviosStrings: true
        });
    }

    private updateServicePanel() {
        if (!this.instance || !this.project) {
            return;
        }
        const inverters = this.instance.getObjects({ types: ["inverter"] }) as Solar.ObjectInverter[];
        if (!inverters.length) {
            return;
        }

        // remove previous panel
        this.instance.removeObjects({ types: ["main_service_panel"] });

        // remove from the config as well
        const config = this.project.electrical.stringsConfig;
        config.items.find(i => i.object.type == "main_service_panel");


        const invPos = inverters[0].position;
        const invRot = inverters[0].rotation;

        // create new one
        const newPanel = {
            id: this.instance.getUniqueId(),
            type: "main_service_panel",
            name: this._translate.instant("Main Service Panel"),
            position: { x: invPos.x, y: invPos.y + 1, z: 0.5 },
            rotation: { x: 0, y: 0, z: invRot?.z },
            raiting: 200,
            mainBreaker: 200,
            // calculated base on 120% rule = 200 * 1.2 - 200 = 40
            pvBreaker: 40,
            connectionType: "load-side-tap",
            preventDeleting: true
        } as Solar.ObjectMainServicePanel;

        const obj3d = this.instance.createObject(newPanel, this.project.measurement);
        if (!obj3d) {
            return;
        }
        this.instance.scene.add(obj3d);


        // attach inverters to panel
        const invertersItems = config.items.filter(i => i.object.type == "inverter");
        // remove them from the config top level
        config.items = config.items.filter(i => i.object.type != "inverter")

        // connect all inverters to the mains panel
        const panelItem: Solar.StringConfigItem = {
            name: newPanel.name,
            object: newPanel,
            children: invertersItems
        }
        config.items.push(panelItem);
    }

    /**
     * Opens a dialog for manually adding inverters to the design.
     * Removes previous inverters, places new inverters on the design,
     * and wires strings based on the recommended size strategy.
     */
    public async manualPlacement() {
        try {
            const isValid = this.verifyModules();
            if (!isValid) {
                return;
            }

            const existInvertersObjects = this.instance.getObjects({ types: ["inverter"] }) as Solar.ObjectInverter[];
            const existInverters: Solar.Inverter[] = [];
            for (const invObj of existInvertersObjects) {
                if (existInverters.find(i => i._id == invObj.inverter._id)) {
                    continue;
                }
                existInverters.push(invObj.inverter);
            }

            const dialogRef = this._matDialog.open(InvertersAddingComponent, {
                autoFocus: true,
                hasBackdrop: true,
                maxWidth: "95vw",
                data: {
                    allocatedInverters: existInverters,
                    voltLevel: this.project.voltageLevel
                }
            });
            const userDefinedInvs = await dialogRef.afterClosed().toPromise() as Solar.Inverter[];
            if (!userDefinedInvs || !userDefinedInvs.length) {
                return;
            }

            const filter = userDefinedInvs.map(i => i._id as string);
            const info = this._reportService.getSegmentsInfo(this.instance);
            const result = await this._inverterService.buildInverters(this.capacityRatio, info.power, this.project.voltageLevel, filter);
            if (!result || !result.length) {
                throw `Inverters are not detected`
            }

            const inverters: Solar.Inverter[] = [];
            for (const res of result) {
                for (let idx = 0; idx < res.count; idx++) {
                    inverters.push(CloneTool.clone(res.inverter));
                }
            }

            this.cleanupWiring();

            await this.doWiring(inverters.map(i => i._id || ""));
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    private async doWiring(filterInverterIds?: string[]) {
        const segmentGroups = this.getSegmentsGroups();

        // position offset for inverters
        let invStartOffsetX = 0;

        for (const segmenetsGroup of segmentGroups.values()) {
            const dcPower = segmenetsGroup.reduce((prev, cur) => prev + (cur?.output.power || 0), 0);
            if (!dcPower) {
                continue;
            }

            const result = await this._inverterService.buildInverters(this.capacityRatio, dcPower, this.project.voltageLevel, filterInverterIds);
            if (!result || !result.length) {
                console.warn(`Inverters are not detected`);
                continue;
            }

            const inverters: Solar.Inverter[] = [];
            for (const res of result) {
                for (let idx = 0; idx < res.count; idx++) {
                    inverters.push(CloneTool.clone(res.inverter));
                }
            }

            // place inverters on the design
            const inverterObjectsIds = this.placeInvertersOnDesign(inverters, invStartOffsetX, 3);
            invStartOffsetX += (inverters.length * 3);

            this.doWiringSegments(inverterObjectsIds, segmenetsGroup.map(s => s.id));
        }

        // update the service panel and place it on the design
        this.finalizeWiring();
    }

    private cleanupWiring() {
        // remove previous inverters, optimizers and strings

        this.instance.removeObjects({ types: ["inverter", "string", "optimizer"] });

        // clear optimizers
        const modules = this.instance.getObjects({ types: ["module"] }) as Solar.ObjectModule[];
        for (let m of modules) {
            m.optimizerId = "";
        }
    }

    /**
     * Verifies the modules in the rooftop component.
     * 
     * @returns {boolean} Returns true if the modules are valid, false otherwise.
     */
    private verifyModules(): boolean {
        const modules = this.instance.getObjects({ types: ["module"] });
        if (modules.length == 0) {
            this._dialogService.confirm({
                text: "Need select one type module at least",
                title: "",
                hideCancel: true
            })
            return false;
        }

        const segments = this.instance.getObjects({ types: ["segment"] }) as Solar.ObjectRooftopSegment[];
        const existModules: string[] = [];
        for (const segment of segments) {
            if (!segment.module) {
                continue;
            }
            const key = `${segment.module.manufacturer} ${segment.module.model}`;
            if (!existModules.includes(key) && existModules.length) {
                this._dialogService.confirm({
                    text: "Multiple model module are not supported",
                    title: "",
                    hideCancel: true
                })
                return false;
            }

            existModules.push(key);
        }

        return true;
    }

    /**
     * Places the inverters on the design and returns an array of their object IDs.
     * 
     * @param inverters - An array of inverters to be placed on the design.
     * @param offsetX - The offset on the x-axis for the starting position of the inverters.
     * @param stepX - The step size on the x-axis between each inverter.
     * @returns An array of object IDs representing the placed inverters.
     */
    private placeInvertersOnDesign(inverters: Solar.Inverter[], offsetX: number, stepX: number): string[] {

        const inverterObjectsIds: string[] = [];

        const sceneBbox = this.instance.getExtent(false, ["roof", "segment"]);

        const width = inverters.length * stepX;
        let xStart = ((sceneBbox.min.x + sceneBbox.max.x) / 2) - (width / 2) + offsetX;

        for (const [idx, inverter] of inverters.entries()) {
            const color = WebSolarColors.stringsColors[Math.min(WebSolarColors.stringsColors.length - 1, idx)];
            const solarObject = {
                id: this.instance.getUniqueId(),
                type: "inverter",
                name: `${inverter.manufacturer} ${inverter.model}`,
                position: { x: xStart, y: sceneBbox.max.y + 2, z: 0 },
                rotation: { x: 0, y: 0, z: Math.PI / 2 },
                inverter: inverter,
                color: color
            } as Solar.ObjectInverter;

            xStart += stepX;

            // add to scene
            const sceneObject = this.instance.createObject(solarObject);
            if (!sceneObject) {
                continue;
            }
            this.instance.scene.add(sceneObject);

            if (idx == 0) {
                // attach the first object
                this.instance.objectEditor.attach(sceneObject);
            }

            inverterObjectsIds.push(solarObject.id);
        }

        return inverterObjectsIds;
    }

    public onNew() {
        this.toolActivated.emit({ type: "inverter", params: null });
    }


    public onItemChange(item: Solar.ObjectInverter) {
        // notify about changes
        this.inverterChange.emit(item)
    }

    public onToolActivated(opt: Solar.ToolOptions) {
        this.toolActivated.emit(opt)
    }

    /**
     * Place optimizers manually
     */
    public async placeOptimizer() {
        try {
            this.isOptimizerPlacing = true;

            if (InvertersListComponent._lastOptimizerMode == "auto") {
                // remove previous
                this.instance.removeObjects({ types: ["optimizer"] });
            }
            InvertersListComponent._lastOptimizerMode = "manual";

            await this._optimizerService.placeManually(this.instance, this.project);

            this.refreshStat();
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isOptimizerPlacing = false;
        }
    }

    /**
     * Deletes an optimizer.
     */
    public deleteOptimizer() {
        this.toolActivated.emit({ type: "delete", params: ["optimizer"] as Solar.ObjectType[] })
    }

    /**
     * Runs the auto optimizers for the electrical panel.
     * If the optimizer is not available, the function returns immediately.
     * Refreshes the statistics after running the optimizer.
     * If an error occurs, it displays the error using the _notify.error() method.
     */
    public async runAutoOptimizers() {
        try {
            if (!this.hasOptimizer) {
                return;
            }

            if (InvertersListComponent._lastOptimizerMode == "manual") {
                // remove previous
                this.instance.removeObjects({ types: ["optimizer"] });
            }
            // store the action
            InvertersListComponent._lastOptimizerMode = "auto";

            this.isOptimizerRunning = true;
            await this._optimizerService.runAutoPlacement(this.instance, this.project);

            this.refreshStat();
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isOptimizerRunning = false;
        }
    }

    /**
     * Refreshes the statistics related to inverters, modules, strings, and capacitor ratio.
     */
    public refreshStat() {
        // update inverters count
        this.invertersCount = this.instance.getObjects({ types: ["inverter"] }).length;

        if (!this.project.electrical.stringsConfig) {
            return
        }

        const flatTree = this._wiringService.getFlatTree(this.project.electrical.stringsConfig.items);


        const modules = this.instance.getObjects({ types: ["module"] }) as Solar.ObjectModule[];
        this.totalModules = modules.length;

        const strings = this.instance.getObjects({ types: ["string"] }) as Solar.ObjectString[];
        this.totalStrings = strings.length;

        // get completed strings
        const connectedStringItems = flatTree.filter(t => t.object.type == "string");
        this.completedStrings = connectedStringItems.length;

        this.modulesInString = 0;
        for (const str of connectedStringItems) {
            this.modulesInString += (str.object as Solar.ObjectString).moduleIds.length;
        }

        const optimizers = this.instance.getObjects({ types: ["optimizer"] }) as Solar.ObjectOptimizer[];
        this.optimizerCount = optimizers.length;
        this.completedOptimizerCount = 0;
        let handledOptimizerIds: string[] = [];
        for (const connectedStringItem of connectedStringItems) {
            const str = connectedStringItem.object as Solar.ObjectString;
            const strModules = modules.filter(m => str.moduleIds.includes(m.id));
            for (let m of strModules) {
                if (m.optimizerId) {
                    if (optimizers.find(o => o.id == m.optimizerId)) {
                        if (!handledOptimizerIds.includes(m.optimizerId)) {
                            handledOptimizerIds.push(m.optimizerId);
                            this.completedOptimizerCount++;
                        }
                    }
                }
            }
        }


        // calculate capacitor ratio
        this.updateCapacityRatio();

        this.updateOptimizer();
    }


    private updateOptimizer() {
        const inverters = this.instance.getObjects({ types: ["inverter"] }) as Solar.ObjectInverter[];
        const strings = this.instance.getObjects({ types: ["string"] });
        if (strings.length) {
            this.hasOptimizer = !!inverters.find(i => i.optimizer)?.optimizer;
        }
        else {
            this.hasOptimizer = false;
        }
    }

    /**
     * Formats the given index by padding it with leading zeros.
     * 
     * @param index - The index to format.
     * @returns The formatted index as a string.
     */
    public formatIndex(index: number) {
        return index.toString().padStart(2, '0');
    }

    public getWiredStrings(item: Solar.StringConfigItem) {
        const flatTree = this._wiringService.getFlatTree(item.children);
        return flatTree.filter(t => t.object.type == "string").length;
    }

    public getStringCount(item: Solar.StringConfigItem) {
        if (item.object.type == "inverter") {
            const inv = item.object as Solar.ObjectInverter;
            if (!inv.inverter.numberMPPT) {
                return 0;
            }
            const invExt = inv.inverter as AIKO.InverterExt;
            if (invExt.stringsPerMPPTLimits?.length) {
                if (invExt.stringsPerMPPTLimits.length == 1) {
                    return inv.inverter.numberMPPT * invExt.stringsPerMPPTLimits[0];
                }
                else {
                    let count = 0;
                    for (let limit of invExt.stringsPerMPPTLimits) {
                        count += limit;
                    }
                    return count;
                }
            }
            else if (inv.inverter.stringsPerMPPT) {
                return inv.inverter.numberMPPT * inv.inverter.stringsPerMPPT;
            }
            return 0;
        }
        else if (item.object.type == "mppt") {
            if (this.strTreeItems[item.object.id]) {
                return this.strTreeItems[item.object.id].length;
            }
        }
        return 0;
    }

    public getModules(item: Solar.StringConfigItem) {
        if (item.object.type == "string") {
            return (item.object as Solar.ObjectString).moduleIds.length;
        }
        else {
            const flatTree = this._wiringService.getFlatTree(item.children);
            const strs = flatTree.filter(t => t.object.type == "string").map(s => s.object as Solar.ObjectString);
            return strs.reduce((prev, cur) => cur.moduleIds.length + prev, 0);
        }
    }

    public getCapacity(item: Solar.StringConfigItem) {
        if (item.object.type == "inverter") {
            const inv = item.object as Solar.ObjectInverter;
            const dcPower = this.getPower(item);
            return Math.round(dcPower / inv.inverter.maxPower * 100);
        }
        return 0;
    }

    public getPower(item: Solar.StringConfigItem) {
        const flatTree = this._wiringService.getFlatTree(item.children);
        const strs = flatTree.filter(t => t.object.type == "string").map(s => s.object as Solar.ObjectString);
        const ids = strs.reduce((prev, cur) => cur.moduleIds.concat(prev), [] as string[]);

        const modules = this.instance.getObjects({ id: ids }) as Solar.ObjectModule[];

        return Math.round(modules.reduce((prev, cur) => cur.modulePower + prev, 0) / 100) / 10;
    }

    public getIsc(item: Solar.StringConfigItem) {
        if (item.object.type == "string") {
            const str = (item.object as Solar.ObjectString);
            const segements = this.instance.getObjects({ id: str.owner }) as Solar.ObjectRooftopSegment[];
            return segements[0]?.module?.isc || 0;
        }
        return 0;
    }

    public getVdc(item: Solar.StringConfigItem) {
        if (item.object.type == "string") {
            const str = (item.object as Solar.ObjectString);
            const segements = this.instance.getObjects({ id: str.owner }) as Solar.ObjectRooftopSegment[];
            const voc = segements[0]?.module?.voc || 0;
            return Math.round(voc * str.moduleIds.length * 10) / 10;
        }
        return 0;
    }

    /**
     * Toggles the state of an item.
     * @param item - The item to toggle.
     */
    public toggleState(item: Solar.StringConfigItem) {
        const id = item.object.id;
        if (!this.itemState[id]) {
            return;
        }
        this.itemState[id].expanded = !this.itemState[id].expanded;

        if (item.object.type == "inverter" ||
            item.object.type == "mppt"
        ) {
            // highligt inverter and strings
            this.highlightOnDesign(item);
        }
    }

    public highlightOnDesign(item?: Solar.StringConfigItem | null) {
        this.instance.highlight.clear();

        if (!item) {
            return;
        }

        this.pickedObjectId = item.object.id;

        if (item.object.id) {
            const objs = this.instance.get3dObjects({ id: [item.object.id] });
            for (const obj of objs) {
                this.instance.highlight.add(obj);
            }
        }

        const flatTree = this._wiringService.getFlatTree(item.children);
        const strIds = flatTree.filter(s => s.object.type == "string").map(s => s.object.id);
        const strings = this.instance.get3dObjects({ id: strIds });
        for (const str of strings) {
            this.instance.highlight.add(str);
        }
    }

    private getUniqueId(): string {
        return this.instance.getUniqueId();
    }

    public getInverter(item: Solar.StringConfigItem) {
        return (item.object as Solar.ObjectInverter);
    }

    private initState(objId: string) {
        if (typeof this.itemState[objId] != "undefined") {
            return;
        }
        this.itemState[objId] = {
            expanded: false
        };
    }

    /**
     * Attaches a string to the given mppt.
     */
    public async attachString(invItem: Solar.StringConfigItem, mppt: Solar.StringConfigItem, str: Solar.ObjectString) {
        const stringName = this.getStringName(str);

        const newStrItem = {
            name: stringName,
            object: str,
            children: []
        } as Solar.StringConfigItem;

        const canAdd = await this.canStringAdded({
            parent: mppt,
            stringItem: newStrItem
        });
        if (!canAdd) {
            this.rebuild();
            return;
        }

        mppt.children.push(newStrItem);

        str.color = (invItem.object as Solar.ObjectString).color;
        this.update3dObject(str);

        this.rebuild();
    }

    private getStringName(strObj: Solar.ObjectString): string {
        return this._wiringService.getStringName(strObj, this.instance)
    }

    public async deleteInverter(event: MouseEvent, invItem: Solar.StringConfigItem) {
        event.stopPropagation();

        if (invItem.object.type != "inverter") {
            return;
        }

        const confirm = await this._dialogService.confirm({
            title: `Delete inverter`,
            text: `Are you sure you want to delete this inverter?`,
            okBtn: "Delete"
        })
        if (!confirm) {
            return;
        }
        // remove children
        this.instance.removeObjects({
            ownerId: invItem.object.id
        });

        // delete inverter as well
        this.instance.removeObjects({
            id: invItem.object.id
        });

        // delete associated strings
        //
        const strings = this._wiringService.getFlatTree(invItem.children)
            .filter(s => s.object.type == "string")
            .map(s => s.object as Solar.ObjectString);
        this.instance.removeObjects({
            id: strings.map(s => s.id)
        });

        // sync the config
        this._wiringService.sync(this.instance, this.project);

        // rebuild
        this.rebuild();
    }

    /**
    * Highlight unmapped modules
    */
    public highlightUnmapped() {
        const meshes = this.instance.get3dObjects({ types: ["module"] });
        const strings = this.instance.getObjects({ types: ["string"] }) as Solar.ObjectString[];
        const mappedIds: string[] = [];
        for (const str of strings) {
            mappedIds.push(...str.moduleIds);
        }
        this.instance.highlight.clear();
        for (const mesh of meshes) {
            const module = mesh.userData as Solar.ObjectModule;
            if (mappedIds.includes(module.id)) {
                continue;
            }
            this.instance.highlight.add(mesh, "mapping_group");
        }
    }

    public async editInverter(event: MouseEvent, invItem: Solar.StringConfigItem) {
        event.stopPropagation();

        const objInv = invItem.object as Solar.ObjectInverter;
        const dialogRef = this._matDialog.open(InverterEditFieldsComponent, {
            autoFocus: true,
            hasBackdrop: true,
            maxWidth: "95vw",
            data: {
                inverter: objInv,
                instance: this.instance
            }
        });
        const confrim = await dialogRef.afterClosed().toPromise();
        if (!confrim) {
            return;
        }

        // setup optimizer
        const inverters = this.instance.getObjects({ types: ["inverter"] }) as Solar.ObjectInverter[];
        this.hasOptimizer = !!inverters.find(i => i.optimizer)?.optimizer;
    }

    public async editOptimizer(evt: MouseEvent, invItem: Solar.StringConfigItem) {
        try {
            evt.preventDefault();
            evt.stopPropagation();

            try {
                const inverterObj = (invItem.object as Solar.ObjectInverter)
                const dialogRef = this._matDialog.open(OptimizerPickerWindowComponent, {
                    autoFocus: true,
                    hasBackdrop: true,
                    maxWidth: "95vw",
                    data: {
                        inverterId: inverterObj.inverter._id || "",
                        optimizerId: inverterObj.optimizer?._id || ""
                    }
                });
                const optimizer = await dialogRef.afterClosed().toPromise() as Solar.Optimizer;
                if (!optimizer) {
                    return;
                }
                // set optimizer
                inverterObj.optimizer = optimizer;
                this.hasOptimizer = true;
            }
            catch (err) {
                this._notify.error(err);
            }

        }
        catch (err) {
            this._notify.error(err);
        }
    }

    /**
     * Deletes a string configuration item.
     * 
     * @param event - The mouse event that triggered the delete action.
     * @param strItem - The string configuration item to be deleted.
     * @returns A promise that resolves when the delete operation is complete.
     */
    public async deleteString(event: MouseEvent, strItem: Solar.StringConfigItem) {
        event.stopPropagation();

        if (strItem.object.type != "string") {
            return;
        }

        const confirm = await this._dialogService.confirm({
            title: `Delete string`,
            text: `Are you sure you want to delete this string?`,
            okBtn: "Delete"
        })
        if (!confirm) {
            return;
        }
        // remove children
        this.instance.removeObjects({
            ownerId: strItem.object.id
        });

        // delete inverter as well
        this.instance.removeObjects({
            id: strItem.object.id
        });

        // sync the config
        this._wiringService.sync(this.instance, this.project);

        // rebuild
        this.rebuild();

        this.instance.getHint().setMessage("Double-click the modules to connect into a string", 3000);
    }


    public async disconnectString(event: MouseEvent, mppt: Solar.StringConfigItem, strItem: Solar.StringConfigItem) {
        event.stopPropagation();

        if (strItem.object.type != "string") {
            return;
        }

        const idx = mppt.children.indexOf(strItem);
        mppt.children.splice(idx, 1);

        // reset the color
        strItem.object.color = "";
        this.update3dObject(strItem.object);

        this.rebuild();
    }

    public async pickString(invItem: Solar.StringConfigItem, mppt: Solar.StringConfigItem) {

        const flatTree = this._wiringService.getFlatTree(this.project.electrical.stringsConfig.items);
        const attachedIds = flatTree.filter(t => t.object.type == "string").map(t => t.object.id);
        const strings = this.instance.getObjects({ types: ["string"] });
        const allowdIds = strings.filter(s => !attachedIds.includes(s.id)).map(s => s.id);

        const selectedObjects = await this.instance.activateTool(
            {
                params: { id: allowdIds },
                type: "select",

            },
            this.project,
            true) as Solar.Object[];

        if (!selectedObjects || !selectedObjects.length) {
            return;
        }

        for (const selObj of selectedObjects) {

            const strObj = selObj as Solar.ObjectString;

            const newStrItem: Solar.StringConfigItem = {
                name: this.getStringName(strObj),
                object: selObj,
                children: []
            }

            const canAdd = await this.canStringAdded({
                parent: mppt,
                stringItem: newStrItem
            });
            if (!canAdd) {
                this.rebuild();
                return;
            }

            mppt.children.push(newStrItem);

            // set a color
            strObj.color = (invItem.object as Solar.ObjectString).color;
            this.update3dObject(strObj);
        }

        this.rebuild();
    }



    public async canStringAdded(opt: {
        parent: Solar.StringConfigItem;
        stringItem: Solar.StringConfigItem
    }): Promise<boolean> {
        if (!opt ||
            !opt.parent ||
            !opt.stringItem) {
            return false;
        }

        if (!this.project ||
            !this.project.electrical ||
            !this.project.electrical.stringsConfig) {
            return false;
        }

        const flatTree = this._wiringService.getFlatTree(this.project.electrical.stringsConfig.items);

        const isAdded = flatTree.find(s => s.object.id == opt.stringItem.object.id);
        if (isAdded) {
            await this._dialogService.confirm({
                title: ``,
                text: `The string already connected to the inverter`,
                okBtn: "Ok"
            })
            return false;
        }

        const strings = opt.parent.children.filter(s => s.object.type == "string");

        if (opt.stringItem.object.type == "string" &&
            opt.parent.object.type == "mppt" &&
            strings.length > 0) {
            // check the condition
            const firstString = strings[0].object as Solar.ObjectString;

            const newString = opt.stringItem.object as Solar.ObjectString;

            let isValid = true;
            if (newString.moduleIds.length != firstString.moduleIds.length ||
                newString.owner != firstString.owner) {
                isValid = false;
            }


            // check maximum allowed strings per mppt
            //
            const invParent = this._wiringService.findParent(this.project.electrical.stringsConfig, opt.stringItem, "inverter");
            if (invParent && invParent.object) {
                const invObj = invParent.object as Solar.ObjectInverter;
                if (invObj && invObj.inverter) {
                    const invExt = invObj.inverter as AIKO.InverterExt;

                    if (typeof invObj.inverter.stringsPerMPPT == "number" && invObj.inverter.stringsPerMPPT > 0) {
                        if (strings.length > invObj.inverter.stringsPerMPPT) {
                            isValid = false;
                        }
                    }
                }
            }

            if (!isValid) {
                // remove the string and show warning 
                opt.parent.children = opt.parent.children.filter(i => i != opt.stringItem);

                // reset the string color
                newString.color = "";
                this.update3dObject(newString);

                await this._dialogService.confirm({
                    title: ``,
                    text: `The string under the same MPPT needs to maintain a consistent component model, inclination angle, ` +
                        `azimuth angle, and number of series connections.`,
                    okBtn: "Confirm"
                })
                return false;
            }
        }

        return true;
    }

    private update3dObject(str: Solar.Object) {
        this.instance.removeObjects({ id: str.id });
        // add new 3d object
        const newObj = this.instance.createObject(str, this.project.measurement);
        if (newObj) {
            this.instance.scene.add(newObj);
        }
    }

    /**
     * Prevents the input of decimal separators (dot or comma) in the specified event.
     * @param evt - The keyboard event.
     */
    public preventDecimal(evt: KeyboardEvent) {
        // Check if the pressed key is a decimal separator (dot or comma)
        if (evt.key === '.' || evt.key === ',' || evt.key === '-') {
            // Prevent the default action (inserting the character)
            evt.preventDefault();
        }
    }

    /**
     * Handles the change event for the capacity ratio.
     * Updates the capacity ratio of the project.
     */
    public onCapacityRatioChange() {
        const proj = this.project as AIKO.ProjectExt;
        proj.capacityRatio = this.capacityRatio;
    }

    public getOptimizer(invItem: Solar.StringConfigItem) {
        const invObj = invItem.object as Solar.ObjectInverter;
        return invObj.optimizer;
    }

    public onVoltageChange() {

    }
}
